/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import { Box, Button, Container, Grid } from '@mui/material';
import ExploreIcon from '@mui/icons-material/Explore';
import ReportIcon from '@mui/icons-material/Report';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { useRouter } from 'next/router';

function ClientSideError ({ statusCode }) {
  const router = useRouter();

  const error = {
    '401': {
      title:   'Unauthorized',
      message: 'You do not have permission to view this page. If you believe there was a mistake please contact support.',
      icon:    <ReportIcon sx={{ fontSize: 200 }} />
    },
    '404': {
      title:   'Page Not Found',
      message: 'The URL you navigated to is missing. This can either be because you typed the URL incorrectly or we\'ve moved the page.',
      icon:    <ExploreIcon className={statusCode === '404' ? '' : 'spin element'} sx={{ fontSize: 200 }} />
    },
    '500': {
      title:   'Internal Server Error',
      message: 'An internal server error occured. We apologize for the inconvience, please try again later.',
      icon:    <DangerousIcon sx={{ fontSize: 200 }} />
    }
  };

  return (
    <Container className='component-cserror'>
      <Box className="component-cserror centered container shadow">
        <Grid
          spacing={6}
          container>
          <Grid
            id="cserror-title"
            className="text-center"
            item
            xs={12}>
            {error[statusCode].icon}
            <h2>{error[statusCode].title}</h2>
          </Grid>

          <Grid
            id="cserror-message"
            className="text-center"
            item
            xs={12}>
            {error[statusCode].message}
          </Grid>

          <Grid
            id="cserror-btn-nav-away"
            className="text-center"
            item
            xs={12}>
            <Button
              color='white'
              onClick={() => router.push('/login')}
              variant='outlined'>Navigate back to the home page</Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export { ClientSideError as default, ClientSideError };
